
import {Component, Vue} from 'vue-property-decorator' 
@Component
export default class trolley extends Vue {
    trolleys = [{id:0, pic: require('../assets/01.jpg'), content:"使い捨てマスク",price:8000,num:1},
                {id:1, pic: require("../assets/03.jpg"), content:"一体型防護服",price:20000,num:2}
    ]

    numPrice (trolley) { 
        return trolley.price * trolley.num
    }
    totalPrice(){
        return this.trolleys.reduce((a,b)=>{
            return a + b.price * b.num
        },0)
    }
    cancelTrolley(a){
        this.trolleys.splice(a,1)
    }
    handin(){


    }
}
