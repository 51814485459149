
import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import banner from '@/components/banner.vue'

import foot from '@/components/foot.vue'
import sidebar from '@/components/sidebar.vue'
import {getUserInfo} from './api/ApiPath'
@Component({
  components:{
    banner, foot, sidebar
  }
})
export default class App extends Vue{
      user = ""
      getToken(){
      const token = localStorage.getItem('token')
      this.user = JSON.parse(token)
    }

  // getInfo(){
  //   getUserInfo(
  //     res => {
  //        const {status, data, msg} = res
  //        if (res.status == 200){
  //          this.userInfo = data
  //        }else{
  //          this.userInfo= {'userId': -1}
  //        }
  //     }
  //   )
  // }
  mounted(){
    this.getToken();
  }
}
