
import {Component, Vue} from "vue-property-decorator"
import { getproduct } from "../api/ApiPath"

@Component
export default class right extends Vue{
    brand:string=""; 
    type:string="";
	bestBuys=[];
    brands=[
        {value:"cft", label:"cft"},
        {value:"sony", label:"sony"},
        {value:"honda", label:"本田", disabled: true},
        {value:"toshiba", label:"日立"}]
    types=[
        {value: "卫生制裁", label:"卫生制裁"},
        {value: "建材土木制裁", label:"建材土木制裁"},
        {value: "环境机器", label:"环境机器"},
        {value: "沙发", label:"沙发"},]
        minPrice:number=0
        maxPrice:number=80000
        submitPrice(){
            console.log("确定价格")
        }
    newItems=[
        {id:1, content:"コンピュータシステムを利用したアプリケーション、ソフトウェア の企画・販売・運用",time:"21/07/01"},
        {id:2, content:"コンピュータシステムに関わるコンサルティング",time:"21/07/02"},
        {id:3, content:"コンピュータシステムに関わる構築、開発、保守、運用業務",time:"21/07/01"},
        {id:4, content:"電子機器及びソフトウェア等の販売",time:"21/07/01"},
        {id:5, content:"日用品雑貨卸売業",time:"21/07/01"},
        {id:6, content:"通信販売事業（ネット通販・カタログ通販）",time:"21/07/01"},
        {id:7, content:"輸出入業務 ",time:"21/07/01"},
        {id:8, content:"製品開発（国内・海外パートナーとのOEM製品の共同開発）",time:"21/07/01"},
    ]
    EMS =[
        {id:1,content:"•斎藤 様 番号:EA01234567N"},        
        {id:2,content:"•斎藤 様 番号:EA01234567N"},        
        {id:3,content:"•斎藤 様 番号:EA01234567N"},        
        {id:4,content:"•斎藤 様 番号:EA01234567N"},        
        {id:5,content:"•斎藤 様 番号:EA01234567N"},        
        {id:6,content:"•斎藤 様 番号:EA01234567N"},
        {id:7,content:"•斎藤 様 番号:EA01234567N"},        
        {id:8,content:"•斎藤 様 番号:EA01234567N"},
    ]
    // bestBuys=[
    //     {id:39, pic:require("../assets/4353.jpg"), content:"透明PVC手套",price:5000},
    //     {id:40, pic:require("../assets/4337.jpg"), content:"合成手套",price:8000},
    //     {id:41, pic:require("../assets/4344.jpg"), content:"白色口罩",price:500},
    //     {id:42, pic:require("../assets/4345.jpg"), content:"蓝色口罩",price:500}
    //     ]
    hotBuys=[
        {id:39, pic:require("../assets/4353.jpg"), content:"透明PVC手套",price:5000},
        {id:40, pic:require("../assets/4337.jpg"), content:"合成手套",price:8000},
        {id:41, pic:require("../assets/4344.jpg"), content:"白色口罩",price:500},
        {id:42, pic:require("../assets/4345.jpg"), content:"蓝色口罩",price:500},
    ]
    listItems=[
        {id:1, pic:require("../assets/04.jpg"), content:"フェイスシールド",price:13000},
        {id:2, pic:require("../assets/04.jpg"), content:"フェイスシールド",price:13000},    
        {id:3, pic:require("../assets/04.jpg"), content:"フェイスシールド",price:13000},    
        {id:4, pic:require("../assets/04.jpg"), content:"フェイスシールド",price:13000},    
        {id:5, pic:require("../assets/04.jpg"), content:"フェイスシールド",price:13000},    
    ]

	getThumb(photo) {
    let thumb = require("../assets/" + photo);
    return thumb;
  	}

	mounted(){
		getproduct(res=>{
			const { status, product, msg } = res;
			this.bestBuys = product.reverse().slice(0,4)
  		},(err)=>{this.$message.error(err);})
	}
}

