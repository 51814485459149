
// import Vue from 'vue'
import { Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class banner extends Vue{
  @Prop()
  public  user: object;

  msg:string = "私たちCFT JAPANは、あらゆる環境アセスメントを向上させる選りすぐりの商品・サービスを提供いたします。ビジネステーマは環境・\
  安全・安心。人と未来と地球のために。自然にやさしく、明日をもっと美しく、選りすぐり商品・サービスで、生活者の暮らしと環境の改善と維持に貢献いたします。";
  search:string = "search";

  logout(){
    this.$confirm("确认登出么").then(_=>{
    localStorage.setItem("token",null)
    this.user = null
    location.href='/'
    }).catch(_=>{

    })
  }

  submit(){
    console.log("submit");
}

}
