
import {Component, Vue} from "vue-property-decorator"
@Component
export default class left extends Vue{
    weishengList=[
        {id:1,category:"PVC手套",property:[{id:1,name: "サイズ-S"},{id:2,name: "サイズ-M"},{id:3,name: "サイズ-L"},{id:4,name: "サイズ-XL"},{id:5,name: "サイズ-XXL"}]},
        {id:2,category:"TPE手套",property:[{id:1,name: "10枚入"},{id:2,name: "20枚入り"},{id:3,name: "50枚入り"},{id:4,name: "100枚入り"},{id:5,name: "200枚入り"}]},
        {id:3,category:"LDPE手袋",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:4,category:"赤外線非接触式温度計",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:5,category:"一体型防護服",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:6,category:"歯科成形錠",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:7,category:"吸入器",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
    ]
    jianzhuList=[
        {id:1,category: "シート",property:[{id:1, name:"防炎シート"},{id:2, name:"年数シート"},{id:3, name:"養生シート"},{id:4,name:"遮熱シート<"},{id:5,name:"シート用品"}]},
        {id:2,category: "作業服",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:3,category: "物流机器",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:4,category: "建设施工资材",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:5,category: "工具·大工具",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:6,category: "电源配线",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:7,category: "清扫用品",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
        {id:8,category: "农业资材",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]}
    ]
    huanjingList=[
      {id:1,category: "晓马系列",property:[{id:1,name: "GSH"},{id:2,name: "ZSS"},{id:3,name: "ZTSZTTS"},{id:4,name: "ZXS"}]},
      // {id:2,category: "暂缺",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
      // {id:3,category: "暂缺",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]}
    ]
    shafaList=[
      {id:1,category: "暂缺",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
      {id:2,category: "暂缺",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]},
      {id:3,category: "暂缺",property:[{id:1,name: "规格待定"},{id:2,name: "规格待定"},{id:3,name: "规格待定"}]}
    ]

    newItem=[
      {id:1, pic_url:require("../assets/erwenqiang.jpg"), content:"温度測定範囲	32℃-42.9℃ と表示されます。", price: 14000},
      {id:2, pic_url:require("../assets/erwenqiang.jpg"), content:"温度測定範囲	32℃-42.9℃ と表示されます。", price: 14000},
      {id:3, pic_url:require("../assets/erwenqiang.jpg"), content:"温度測定範囲	32℃-42.9℃ と表示されます。", price: 14000},
      {id:4, pic_url:require("../assets/erwenqiang.jpg"), content:"温度測定範囲	32℃-42.9℃ と表示されます。", price: 14000},
      {id:5, pic_url:require("../assets/erwenqiang.jpg"), content:"温度測定範囲	32℃-42.9℃ と表示されます。", price: 14000}
    ]
};
