
import { Component, Vue } from 'vue-property-decorator';
import carousel from '@/components/carousel.vue'
import left from '@/components/left.vue'
import right from '@/components/right.vue'
import foot1 from '@/components/foot1.vue'
@Component({
  components: {
    left,right,carousel,foot1
  },
})
export default class Home extends Vue {

}
